window.addEventListener("DOMContentLoaded", function () {
    const $slide = this.document.querySelector(".section-home-slide .swiper");

    if (!$slide) return;

    var mySwiper = new Swiper(".section-home-slide .swiper", {
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        slidesPerView: 1,
        speed: 400,
        navigation: {
            nextEl: ".slide-navigation.next",
            prevEl: ".slide-navigation.prev",
        },
        pagination: {
            el: ".slide-pagination",
            type: "bullets",
            bulletClass: "bullet",
            bulletActiveClass: "bullet-active",
        },
    });
});
