window.addEventListener("DOMContentLoaded", function () {
    const $toolbarBanner = this.document.querySelector(".toolbar-banner .swiper");

    if (!$toolbarBanner) return;

    var mySwiper = new Swiper(".toolbar-banner .swiper", {
        autoplay: {
            delay: 4500,
            disableOnInteraction: false,
        },
        slidesPerView: 1,
        autoHeight: true,
        speed: 350,
        allowTouchMove: false,
        loop: true
    });
});
