// Behaviors
require("./bootstrap");

require("./site/01.image-bg");
require("./site/02.anchor-link");
require("./site/03.masks");
require("./site/04.fancybox");
require("./site/05.gallery-carousel");
require("./site/06.banner-home");
require("./site/07.toolbar-banner");

window.addEventListener("DOMContentLoaded", function () {
    var menuHasSubmenu = document.querySelectorAll(".site-header-nav ul.menu li.menu-item-has-children > a");

    if (!menuHasSubmenu.length) return;

    menuHasSubmenu.forEach((element) => {
        element.addEventListener('click', (e) => {
            e.preventDefault();
        });
    });
});

jQuery(document).ready(function ($) {
    $('body').on('added_to_cart', function (e, data) {
        e.preventDefault();

        const modal = new bootstrap.Modal('#modal-cart-added', {
            backdrop: 'static',
        })
        modal.show();

        eventsModal(data['div.widget_shopping_cart_content']);
    });

    function eventsModal(data) {
        const modalCartAdded = document.getElementById('modal-cart-added')

        modalCartAdded.addEventListener('shown.bs.modal', event => {
            $('#modal-cart-content').html(data);
        })
    }
});